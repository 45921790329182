function horizontScroll(){

    const scrollBlock = document.querySelectorAll('.block-long-img')

    scrollBlock.forEach(elem => {
        /*if (elem.addEventListener) {
            // IE9, Chrome, Safari, Opera
            elem.addEventListener('mousewheel', scrollHorizontally, false);
            // Firefox
            elem.addEventListener('DOMMouseScroll', scrollHorizontally, false);
        } else {
            // IE 6/7/8
            elem.attachEvent('onmousewheel', scrollHorizontally);
        }*/

        elem.onmousedown = () => {
            let pageX = 0;

            document.onmousemove = e => {
                if (pageX !== 0) {
                    elem.scrollLeft = elem.scrollLeft + (pageX - e.pageX);
                }
                pageX = e.pageX;
            };

            // заканчиваем выполнение событий
            document.onmouseup = () => {
                document.onmousemove = null;
                timeline.onmouseup = null;
            };

            // отменяем браузерный drag
            elem.ondragstart = () => {
                return false;
            };
        };

    })


    const scrollHorizontally = (e) => {
        alert('asdasd')
        e = window.event || e;
        var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
        this.scrollLeft -= (delta * 40); // Multiplied by 40
        e.preventDefault();
    }
}

export default horizontScroll;
