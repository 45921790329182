function fullSlider({container, slide, nextArrow, prevArrow, totalCounter, currentCounter, wrapper, field, dotsElem, auto, dataWrapper, hideData, showDataWrapper, showData}) {
    // Slider

    const slider = document.querySelector(container),
        slides = slider.querySelectorAll(slide),
        prev = slider.querySelector(prevArrow),
        next = slider.querySelector(nextArrow),
        total = slider.querySelector(totalCounter),
        current = slider.querySelector(currentCounter),
        slidesWrapper = document.querySelector(wrapper),
        slidesField = slider.querySelector(field),
        dots = slider.querySelectorAll(dotsElem),
        width = window.getComputedStyle(slidesWrapper).width;

    //console.log(width);

    let slideIndex = 1;
    let offset = 0;

    if (slides.length < 10){
        total.textContent = `0${slides.length}`;
        current.textContent = `0${slideIndex}`;
    }else{
        total.textContent = slides.length;
        current.textContent = slideIndex;
    }

    slidesField.style.width = 100 * slides.length + '%';
    slidesField.style.display = 'flex';
    slidesField.style.transition = '0.7s all';

    slidesWrapper.style.overflow = 'hidden';

    slides.forEach(slide => {
        slide.style.width = width;

        const hideInfo = slide.querySelector(hideData)
        const showInfo = slide.querySelector(showData)
        const dataBlock = slide.querySelector(dataWrapper)
        const dataShowBlock = slide.querySelector(showDataWrapper)

        hideInfo.addEventListener('click', () => {
            dataBlock.classList.add('hide-slide-info')
            dataShowBlock.classList.add('show-slide-info')
        })

        showInfo.addEventListener('click', () => {
            dataBlock.classList.remove('hide-slide-info')
            dataShowBlock.classList.remove('show-slide-info')
        })

    });

    slider.style.position = 'relative';


    function deleteNotDigits(str){
        //return +str.replace(/\D/g, '');
        return +str.replace('px', '');
    }

    function autoSlider() {
        if(offset == deleteNotDigits(width) * (slides.length - 1)){
            offset = 0;
        }else{
            offset += deleteNotDigits(width) ;
        }

        moveSlide();

        if(slideIndex == slides.length){
            slideIndex = 1;
        }else{
            slideIndex++;
        }

        setCurrent();

        changeDot();

        setTimeout(autoSlider, 5000);
    }

    if(auto) {
        setTimeout(autoSlider, 5000);
    }

    next.addEventListener('click', () => {
        if(offset == deleteNotDigits(width) * (slides.length - 1)){
            offset = 0;
        }else{
            offset += deleteNotDigits(width) ;
        }

        moveSlide();

        if(slideIndex == slides.length){
            slideIndex = 1;
        }else{
            slideIndex++;
        }

        setCurrent();

        changeDot();
    });

    prev.addEventListener('click', () => {

        if(offset == 0){
            offset += deleteNotDigits(width)  * (slides.length - 1);
        }else{
            offset -= deleteNotDigits(width);
        }

        moveSlide();

        if(slideIndex == 1){
            slideIndex = slides.length;
        }else{
            slideIndex--;
        }


        setCurrent();

        changeDot();
    });

    let moveEvent = null;
    slidesWrapper.addEventListener("touchstart", function (e) {
        moveEvent = e;
    }, {passive: true});
    slidesWrapper.addEventListener("touchmove", function (e) {
        if (moveEvent) {
            let moveSide = e.touches[0].pageX - moveEvent.touches[0].pageX;

            if(moveSide < 0){
                if(moveSide < -30){
                    if(offset == deleteNotDigits(width) * (slides.length - 1)){
                        offset = 0;
                    }else{
                        offset += deleteNotDigits(width) ;
                    }

                    moveSlide();

                    if(slideIndex == slides.length){
                        slideIndex = 1;
                    }else{
                        if(slideIndex == 1){
                            slideIndex = slideIndex+1;
                        }else{
                            slideIndex++;
                        }
                    }

                    setCurrent();
                    changeDot();

                    moveEvent = null;
                }
            }else{
                if(moveSide > 30){
                    if(offset == 0){
                        offset += deleteNotDigits(width)  * (slides.length - 1);
                    }else{
                        offset -= deleteNotDigits(width);
                    }

                    moveSlide();

                    if(slideIndex == 1){
                        slideIndex = slides.length;
                    }else{
                        if(slideIndex == slides.length+1){
                            slideIndex = slideIndex-1;
                        }else{
                            slideIndex--;
                        }

                    }

                    setCurrent();
                    changeDot();
                    moveEvent = null;
                }
            }
        }
    }, {passive: true});

    slidesWrapper.addEventListener("touched", function (e) {
        moveEvent = null;
    }, {passive: true});

    /*dots.forEach(dot => {
        dot.addEventListener('click', (e) => {
            const slideTo = e.target.getAttribute('data-slide-to');

            slideIndex = slideTo;
            offset = deleteNotDigits(width)  * (slideTo - 1);

            moveSlide();

            setCurrent();

            changeDot();
        });
    });*/

    function changeDot(){
        dots.forEach(dot => dot.classList.remove('index-dots-active'));
        dots[slideIndex - 1].classList.add('index-dots-active');
    }

    function setCurrent(){
        if(slides.length < 10){
            current.textContent = `0${slideIndex}`;
        }else{
            current.textContent = slideIndex;
        }
    }

    function moveSlide(){
        slidesField.style.transform = `translateX(-${offset}px)`;
    }

}

export default fullSlider;
