function slider({container, slide, nextArrow, prevArrow, totalCounter, currentCounter, wrapper, field, dotsElem}) {
    // Slider

    const slides = document.querySelectorAll(slide),
        slider = document.querySelector(container),
        prev = document.querySelector(prevArrow),
        next = document.querySelector(nextArrow),
        total = document.querySelector(totalCounter),
        current = document.querySelector(currentCounter),
        slidesWrapper = document.querySelector(wrapper),
        slidesField = document.querySelector(field),
        dots = document.querySelectorAll(dotsElem);

    let slideIndex = 1;
    let offset = 0;
    let width = window.getComputedStyle(slidesWrapper).width;
    let sliderPosition = slider.getBoundingClientRect();
    let windowWidth = document.documentElement.clientWidth;
    let sliderWidth = 680;
    let sliderMargin = 60;
    if(window.screen.width < 1400 && window.screen.width >= 1200){
        sliderWidth = 600;
        sliderMargin = 30;
    }
    if(window.screen.width < 1200 && window.screen.width >= 992){
        sliderWidth = 500;
        sliderMargin = 30;
    }
    if(window.screen.width < 992 && window.screen.width >= 429){
        sliderWidth = 400;
        sliderMargin = 20;
    }
    if(window.screen.width < 429){
        sliderWidth = 300;
        sliderMargin = 15;
    }
    //console.log(sliderPosition.left);
    //console.log(windowWidth);

    if (slides.length < 10){
        total.textContent = `0${slides.length}`;
        current.textContent = `0${slideIndex}`;
    }else{
        total.textContent = slides.length;
        current.textContent = slideIndex;
    }

    slides.forEach(slide => {
        //slide.style.width = width;
        slide.style.width = sliderWidth+'px';
        //sliderWidth = slide.offsetWidth;
    });

    //slidesField.style.width = 100 * slides.length + '%';
    slidesField.style.width = slides.length * sliderWidth + (sliderMargin*(slides.length-1)) + 'px';
    slidesField.style.display = 'flex';
    slidesField.style.transition = '0.6s all';

    //slidesWrapper.style.overflow = 'hidden';



    //console.log(width);
    //console.log(sliderWidth);

    slider.style.position = 'relative';

    function deleteNotDigits(str){
        return +str.replace(/\D/g, '');
    }

    function autoSlider() {

        if(offset == (sliderWidth+sliderMargin) * (slides.length - 1)){
            offset = 0;
        }else{
            offset += sliderWidth+sliderMargin;
        }

        moveSlide();

        if(slideIndex == slides.length){
            slideIndex = 1;
        }else{
            slideIndex++;
        }


        setCurrent();

        changeDot();

        setTimeout(autoSlider, 5000);
    }

    setTimeout(autoSlider, 5000);

    next.addEventListener('click', () => {

        if(offset == (sliderWidth+sliderMargin) * (slides.length - 1)){
            offset = 0;
        }else{
            offset += sliderWidth+sliderMargin;
        }

        moveSlide();

        if(slideIndex == slides.length){
            slideIndex = 1;
        }else{
            slideIndex++;
        }


        setCurrent();

        changeDot();
    });

    prev.addEventListener('click', () => {

        if(offset == 0){
            offset += (sliderWidth+sliderMargin)  * (slides.length - 1);
        }else{
            offset -= sliderWidth+sliderMargin;
        }

        moveSlide();

        if(slideIndex == 1){
            slideIndex = slides.length;
        }else{
            slideIndex--;
        }


        setCurrent();

        changeDot();
    });

    let moveEvent = null;
    slidesWrapper.addEventListener("touchstart", function (e) {
        moveEvent = e;
    }, {passive: true});
    slidesWrapper.addEventListener("touchmove", function (e) {
        if (moveEvent) {
            let moveSide = e.touches[0].pageX - moveEvent.touches[0].pageX;

            if(moveSide < 0){
                if(moveSide < -30){
                    if(offset == (sliderWidth+sliderMargin) * (slides.length - 1)){
                        offset = 0;
                    }else{
                        offset += sliderWidth+sliderMargin;
                    }

                    moveSlide();

                    if(slideIndex == slides.length){
                        slideIndex = 1;
                    }else{
                        if(slideIndex == 1){
                            slideIndex = slideIndex+1;
                        }else{
                            slideIndex++;
                        }
                    }

                    setCurrent();
                    changeDot();

                    moveEvent = null;
                }
            }else{
                if(moveSide > 30){
                    if(offset == 0){
                        offset += (sliderWidth+sliderMargin) * (slides.length - 1);
                    }else{
                        offset -= (sliderWidth+sliderMargin);
                    }

                    moveSlide();

                    if(slideIndex == 1){
                        slideIndex = slides.length;
                    }else{
                        if(slideIndex == slides.length+1){
                            slideIndex = slideIndex-1;
                        }else{
                            slideIndex--;
                        }

                    }

                    setCurrent();
                    changeDot();
                    moveEvent = null;
                }
            }
        }
    }, {passive: true});

    slidesWrapper.addEventListener("touched", function (e) {
        moveEvent = null;
    }, {passive: true});

    function changeDot(){
        dots.forEach(dot => dot.classList.remove('index-dots-active'));
        dots[slideIndex - 1].classList.add('index-dots-active');
    }

    function setCurrent(){
        if(slides.length < 10){
            current.textContent = `0${slideIndex}`;
        }else{
            current.textContent = slideIndex;
        }
    }

    function moveSlide(){
        slidesField.style.transform = `translateX(-${offset}px)`;
    }
}

export default slider;
