function ymap(){

    let map_container = document.querySelector('#map_container');
    let options_map = {
        once: true,
        passive: true,
        capture: true
    };
    map_container.addEventListener('click', start_lazy_map, options_map);
    //map_container.addEventListener('mouseover', start_lazy_map, options_map);
    map_container.addEventListener('touchstart', start_lazy_map, options_map);
    map_container.addEventListener('touchmove', start_lazy_map, options_map);

    let map_loaded = false;
    function start_lazy_map() {

        if (!map_loaded) {
            let map_block = document.getElementById('ymap_lazy');
            map_loaded = true;
            let mapScript = document.createElement('script')
            mapScript.setAttribute('src', 'https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Aa428e2bf157277ef1eae0224a67f6db11c211744d6f0d52e6ce844f400ed5673&amp;width=100%25&amp;height=541&amp;lang=ru_RU&amp;scroll=true')
            mapScript.async = true;
            map_container.appendChild(mapScript);
        }
    }
}

export default ymap;
